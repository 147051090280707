
<template>
  <div>
    <page-header title='Resumo'></page-header>
    <v-row class="px-3">
      <v-col>
        <course-calendar :use_with_menu="true"></course-calendar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CourseCalendar from '@/components/courses/agenda/CourseCalendar.vue'

export default {
  name: 'TechSecretaryHome',
  components: {
    CourseCalendar,
  },
  data() {
    return {
      loading: false,
    }
  },
}
</script>

<style scoped>

</style>